@import "../reset";
@import "../variables";
@import "../mixins/styles";
@import "../mixins/utilities";

.ob-toggle-switch__label {
  @include font-ui-body();
  @include color-normal-active;
}

.ob-toggle-switch {
  position: relative;
  width: 100%;
  height: 6 * $base-size;

  .ob-toggle-switch__label {
    position: absolute;
    left: 0;
    margin: 1.5 * $base-size 0;
  }

  .ob-toggle-switch__button {
    position: absolute;
    right: 0;
    margin: 1.5 * $base-size 0;
  }

  .ob-toggle-switch__divider {
    position: absolute;
    left: 0;
    right: 0;
    height: .125 * $base-size;
    top: 6 * $base-size - .125 * $base-size;
    background: $border-divider-color;
  }
}

.ob-toggle-switch__button {
  @include style-indent(1.5 * $base-size);
  position: absolute;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 6 * $base-size;
  height: 3 * $base-size;

  border-radius: 3 * $base-size;

  @include pointer-on-hover-not-disabled();

  &.ob-selected {
    @include style-selected(1.5 * $base-size);
  }

  & .ob-toggle-switch__thumb {
    border-color: $thumb-enabled-border-color;
    border-style: solid;
    border-width: $thumb-enabled-border-width;

    position: absolute;
    left: - $border-width;
    width: 3 * $base-size;
    height: 3 * $base-size;
    border-radius: 3 * $base-size;

    &::after {
      background-color: $thumb-enabled-background-color;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }

  }

  &.ob-selected .ob-toggle-switch__thumb {
      right: - $border-width;
      left: unset;
  }

  &.ob-disabled .ob-toggle-switch__thumb,
  &:disabled .ob-toggle-switch__thumb{
    background: $on-indent-disabled-color;
    border-width: 0;
    width: 2 * $base-size;
    height: 2 * $base-size;
    left: .5 * $base-size - $border-width;
    &::after {
      background-color: unset;
    }
  }

  &.ob-disabled.ob-selected .ob-toggle-switch__thumb,
  &:disabled.ob-selected .ob-toggle-switch__thumb{
    background: $on-selected-disabled-color;
    left: unset;
    right: .5 * $base-size - $border-width;
  }
}

