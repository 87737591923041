@import "../reset";
@import "../variables";
@import "../mixins/styles";
@import "../mixins/utilities";

$slider-height: 4 * $base-size;
$slider-track-height: .5 * $base-size;

.ob-slider {
  .ob-slider__container {
    height: $slider-height;
    width: 100%;
    display: flex;
    position: relative;
    @include pointer-on-hover-not-disabled;
  }

  .ob-slider__track {
    @include style-indent;
    border-radius: $border-radius-regular;
    position: absolute;
    width: 100%;
    height: $slider-track-height;
    top: calc($slider-height / 2 - $slider-track-height / 2);
  }

  .ob-slider__track--selected {
    background: $element-active-color;
    border-radius: $border-radius-regular;
    position: absolute;
    left: 0;
    height: $slider-track-height;
    top: calc($slider-height / 2 - $slider-track-height / 2);
  }

  .ob-slider__item {
    @include font-ui-button;
    @include style-amplified;
    @include color-amplified-active;
    z-index: 2;
    position: absolute;
    border-radius: $border-radius-regular;
    text-align: center;
    padding: .5 * $base-size - $border-width;

    width: 6 * $base-size;
    height: $slider-height;
  }
}
