@import "../reset";
@import "../variables";
@import "./icon";
@import "../mixins/styles";
@import "../mixins/utilities";

$card-header-height: 5 * $base-size !default;
$card-header-divider-x-margin: .5 * $base-size !default;
$card-header-padding-left: 2 * $base-size !default;
$card-header-padding-icon-text: 1 * $base-size !default;
$card-header-action-icon-width: 6 * $base-size !default;

.ob-card {
  background-color: $container-background-color;
  border-radius: $border-radius-small;
  display: inline-flex;
  flex-direction: column;

  .ob-card-header {
    display: flex;
    position: relative;
    height: $card-header-height;
    padding-left: $card-header-padding-left;

    .ob-card-header-title-icon {
      color: $element-neutral-color;
      margin-top: auto;
      margin-bottom: auto;
      margin-right: $card-header-padding-icon-text;
    }

    .ob-card-header-title {
      @include font-ui-overline;
      color: $element-active-color;
      margin: auto 0;
    }

    .ob-card-header-action-icon {
      position: absolute;
      height: 100%;
      right: 0;
      width: $card-header-action-icon-width;
      text-align: center;
      color: $on-flat-neutral-color;
      display: flex;

      & * {
        margin: auto;
      }

      &::before {
        content: "";
        position: absolute;
        left: -$border-width;
        top: calc(($card-header-height - $icon-normal-height) / 2);
        bottom: calc(($card-header-height - $icon-normal-height) / 2);
        width: $border-width;
        background-color: $border-divider-color;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: $card-header-divider-x-margin;
      right: $card-header-divider-x-margin;
      height: $border-width;
      bottom: -$border-width;
      background-color: $border-divider-color;
    }
  }
}
