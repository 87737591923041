@import "../reset";
@import "../variables";
@import "../mixins/styles";
@import "../mixins/utilities";

.ob-dimming-menu {
  position: relative;
  width: 34 * $base-size;
  background: $container-global-color;
  @include shadow-floating;
  border-radius: 0 0 $border-radius-small $border-radius-small;

  .ob-section {
    padding: 2 * $base-size 3 * $base-size;

    &:not(:first-child) {
      border-top: $border-width solid $border-outline-color;
    }
  }

  & .ob-section > .ob-heading {
    @include font-ui-overline;
    @include color-normal-neutral;
    text-transform: uppercase;
  }

  .ob-section.ob-list-item {
    @include style-flat;
    @include pointer-on-hover-not-disabled;
    display: flex;

    &:not(:first-child) {
      border-top: $border-width solid $border-outline-color;
    }

    .ob-label {
      @include font-ui-button;
      @include color-normal-active;
      margin-right: auto;
      margin-left: 2.5 * $base-size;
    }

    .ob-icon {
      @include color-normal-neutral;
      width: $icon-normal-height;
      height: $icon-normal-height;
      font-size: $icon-normal-height;
    }
  }
}
