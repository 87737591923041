.ob-visually-hidden {
  position: absolute;

  width: 1px; /* stylelint-disable-line meowtec/no-px */
  height: 1px; /* stylelint-disable-line meowtec/no-px */

  padding: 0;
  margin: -1px; /* stylelint-disable-line meowtec/no-px */
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;

  outline: 0;
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
}


@mixin set-border-width-and-margin-for-constant-margin-box-size($border-width) {
  border-width: $border-width;
}

/** Use this function for unit testing
 *  Sets content to $tag, so that getCalculatedStyle can be used to assert the style with highest priority.
 */
@mixin test-tag($tag) {
  content: $tag;
}

@mixin material-icon($content-height) {
  & > .ob-icon .material-icons {
    margin-top: calc(($content-height - $icon-normal-height) / 2);
  }
}

@mixin thumb {
  width: $btn-diameter-thumb;
  height: $btn-diameter-thumb;

  border-radius: calc($btn-height-small / 2);
}

@mixin pointer-on-hover-not-disabled {
  &:hover:not(:disabled) {
    cursor: pointer;
  }
}

.ob-py-2 {
  padding-top: 2 * $base-size;
  padding-bottom: 2 * $base-size;
}

