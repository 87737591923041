@import "../reset";
@import "../variables";
@import "../mixins/styles";
@import "../mixins/utilities";

$btn-border-radius: $border-radius-regular !default;
$btn-width-min: 12 * $base-size !default;
$btn-width-toggle: 2 * $btn-height-small !default;
$btn-diameter-thumb: $btn-height-small - 1.25 * $base-size !default;
$btn-padding-x: 1 * $base-size !default;
$btn-icon-padding-x: 1 * $base-size !default;
$btn-text-under-spacing: .5 * $base-size !default;
$btn-width-icon: 4 * $base-size !default;
$btn-height-icon: 4 * $base-size !default;

.ob-button {
  @include font-ui-button();
  position: relative;
  display: inline-flex;
  align-items: center;

  min-width: $btn-width-min;
  height: $btn-height-normal;
  padding: 0 $btn-padding-x;
  vertical-align: middle;

  border-radius: $btn-border-radius;

  @include pointer-on-hover-not-disabled();

  & .ob-label {
    margin-left: $btn-padding-x;
  }

  &.ob-button--icon {
    width: $btn-width-icon;
    min-width: $btn-width-icon;
    height: $btn-height-icon;
    padding: 0;
  }

  &.ob-button--normal {
    @include color-normal-active();
    @include style-normal();

    & .ob-icon {
      @include color-normal-neutral();
    }
  }

  &.ob-button--raised {
    @include color-raised-active();
    @include style-raised();

    & .ob-icon {
      @include color-raised-neutral();
    }
  }

  &.ob-button--supressed {
    @include color-flat-neutral;
    @include style-flat;

    & .ob-icon {
      @include color-flat-neutral();
    }
  }

  &.ob-button--alarm .ob-icon{
    color: $alert-alarm-color;
  }

  &.ob-button--warning .ob-icon{
    color: $alert-warning-color;
  }

  &.ob-button--caution .ob-icon{
    color: $alert-caution-color;
  }
  & .ob-button__counter {
    @include color-element-active;
    position: absolute;
    right: -1 * $base-size;
    top: -1 * $base-size;
  }

  .ob-icon {
    margin-left: auto;
    margin-right: auto;
  }
}

