:root[theme=night] {
    --element-active-color: rgb(71, 71, 0);
    --element-neutral-color: rgba(71, 71, 0, .650);
    --element-inactive-color: rgba(71, 71, 0, .450);
    --element-disabled-color: rgba(71, 71, 0, .250);
    --element-active-inverted-color: rgb(0, 0, 0);
    --element-neutral-inverted-color: rgba(0, 0, 0, .600);
    --element-inactive-inverted-color: rgba(0, 0, 0, .400);
    --element-disabled-inverted-color: rgba(0, 0, 0, .200);
    --container-backdrop-color: rgb(5, 5, 0);
    --container-section-color: rgb(0, 0, 0);
    --container-background-color: rgb(0, 0, 0);
    --container-global-color: rgb(10, 10, 0);
    --container-overlay-color: rgba(0, 0, 0, .300);
    --border-divider-color: rgba(71, 71, 0, .200);
    --border-outline-color: rgba(71, 71, 0, .200);
    --border-edge-color: rgba(71, 71, 0, .100);
    --border-silhouette-color: rgb(0, 0, 0);
    --border-icon-outline-color: rgba(0, 0, 0, .500);
    --border-solid-color: rgb(46, 46, 0);
    --instrument-enhanced-primary-color: rgb(0, 77, 32);
    --instrument-enhanced-secondary-color: rgb(0, 51, 21);
    --instrument-enhanced-tertiary-color: rgba(0, 51, 17, .200);
    --instrument-regular-primary-color: rgb(71, 71, 0);
    --instrument-regular-secondary-color: rgb(51, 51, 0);
    --instrument-regular-tertiary-color: rgba(51, 51, 0, .150);
    --instrument-frame-primary-color: rgb(10, 10, 0);
    --instrument-frame-secondary-color: rgb(0, 0, 0);
    --instrument-frame-tertiary-color: rgb(33, 36, 0);
    --instrument-tick-mark-primary-color: rgb(71, 71, 0);
    --instrument-tick-mark-secondary-color: rgb(51, 51, 0);
    --instrument-tick-mark-tertiary-color: rgb(36, 36, 0);
    --instrument-starboard-color: rgb(12, 39, 33);
    --instrument-port-color: rgb(43, 18, 27);
    --alert-running-color: rgb(23, 56, 0);
    --alert-caution-color: rgb(92, 69, 0);
    --alert-warning-color: rgb(92, 44, 0);
    --alert-alarm-color: rgb(77, 0, 5);
    --alert-critical-alarm-color: rgb(76, 0, 77);
    --alert-limit-primary-color: rgba(0, 0, 0, .200);
    --alert-limit-secondary-color: rgba(0, 0, 0, .200);
    --automation-device-primary-color: rgb(71, 71, 0);
    --automation-device-secondary-color: rgb(26, 26, 0);
    --automation-device-tertiary-color: rgb(0, 0, 0);
    --automation-device-primary-inverted-color: rgb(0, 0, 0);
    --automation-device-secondary-inverted-color: rgb(10, 10, 0);
    --automation-device-tertiary-inverted-color: rgb(51, 51, 0);
    --automation-pipe-primary-color: rgb(31, 31, 0);
    --automation-pipe-secondary-color: rgb(15, 15, 0);
    --automation-pipe-tertiary-color: rgb(0, 0, 0);
    --automation-pipe-primary-inverted-color: rgb(0, 0, 0);
    --automation-pipe-secondary-inverted-color: rgb(15, 15, 0);
    --automation-pipe-tertiary-inverted-color: rgb(32, 32, 0);
    --automation-pipe-overlay-color: rgba(0, 0, 0, .200);
    --automation-pipe-disabled-color: rgba(0, 0, 0, .100);
    --automation-acid-alkalis: rgb(21, 18, 23);
    --automation-air-ventilation: rgb(20, 20, 20);
    --automation-fire-fighting: rgb(25, 16, 18);
    --automation-flammable-gas: rgb(25, 26, 15);
    --automation-fresh-water: rgb(16, 23, 25);
    --automation-fuel: rgb(27, 20, 13);
    --automation-masses: rgb(23, 20, 17);
    --automation-non-flammable-gas: rgb(31, 34, 27);
    --automation-oil: rgb(30, 26, 11);
    --automation-sea-water: rgb(17, 24, 23);
    --automation-steam: rgb(19, 20, 21);
    --automation-waste-media: rgb(20, 20, 20);
    --normal-enabled-background-color: rgb(0, 0, 0);
    --normal-enabled-border-color: rgb(26, 26, 0);
    --normal-hover-background-color: rgb(0, 0, 0);
    --normal-hover-border-color: rgb(36, 36, 0);
    --normal-pressed-background-color: rgb(0, 0, 0);
    --normal-pressed-border-color: rgb(15, 15, 0);
    --normal-focused-background-color: rgb(0, 0, 0);
    --normal-focused-border-color: rgba(0, 51, 21, .500);
    --normal-disabled-background-color: rgba(255, 255, 255, .00);
    --normal-disabled-border-color: rgb(10, 10, 0);
    --normal-activated-background-color: rgba(71, 71, 0, .120);
    --normal-activated-border-color: rgb(0, 0, 0);
    --on-normal-active-color: rgb(71, 71, 0);
    --on-normal-neutral-color: rgba(71, 71, 0, .650);
    --on-normal-disabled-color: rgba(71, 71, 0, .350);
    --raised-enabled-background-color: rgb(15, 15, 0);
    --raised-enabled-border-color: rgb(26, 26, 0);
    --raised-hover-background-color: rgb(15, 15, 0);
    --raised-hover-border-color: rgb(36, 36, 0);
    --raised-pressed-background-color: rgb(0, 0, 0);
    --raised-pressed-border-color: rgb(15, 15, 0);
    --raised-focused-background-color: rgb(15, 15, 0);
    --raised-focused-border-color: rgba(0, 51, 21, .500);
    --raised-disabled-background-color: rgba(0, 0, 0, .00);
    --raised-disabled-border-color: rgb(10, 10, 0);
    --on-raised-active-color: rgb(71, 71, 0);
    --on-raised-neutral-color: rgb(51, 51, 0);
    --on-raised-disabled-color: rgba(71, 71, 0, .350);
    --indent-enabled-background-color: rgb(0, 0, 0);
    --indent-enabled-border-color: rgba(71, 71, 0, .300);
    --indent-hover-background-color: rgba(0, 0, 0, .00);
    --indent-hover-border-color: rgb(25, 26, 0);
    --indent-pressed-background-color: rgba(0, 0, 0, .00);
    --indent-pressed-border-color: rgb(10, 10, 0);
    --indent-focused-background-color: rgba(0, 0, 0, .00);
    --indent-focused-border-color: rgba(0, 51, 21, .500);
    --indent-disabled-background-color: rgba(0, 0, 0, .00);
    --indent-disabled-border-color: rgba(0, 0, 0, .100);
    --on-indent-active-color: rgb(71, 71, 0);
    --on-indent-neutral-color: rgb(51, 51, 0);
    --on-indent-disabled-color: rgb(18, 18, 0);
    --flat-enabled-background-color: rgba(255, 255, 255, .00);
    --flat-enabled-border-color: rgba(0, 0, 0, .00);
    --flat-hover-background-color: rgba(0, 0, 0, .00);
    --flat-hover-border-color: rgb(26, 26, 0);
    --flat-pressed-background-color: rgba(0, 0, 0, .00);
    --flat-pressed-border-color: rgb(15, 15, 0);
    --flat-focused-background-color: rgba(0, 0, 0, .00);
    --flat-focused-border-color: rgba(0, 51, 21, .500);
    --flat-disabled-background-color: rgba(0, 0, 0, .00);
    --flat-disabled-border-color: rgba(0, 0, 0, .00);
    --flat-activated-background-color: rgb(15, 15, 0);
    --flat-activated-border-color: rgba(0, 0, 0, .00);
    --flat-checked-background-color: rgba(0, 71, 30, .150);
    --flat-checked-border-color: rgba(15, 74, 163, .00);
    --on-flat-active-color: rgb(71, 71, 0);
    --on-flat-neutral-color: rgb(51, 51, 0);
    --on-flat-disabled-color: rgba(97, 107, 46, .200);
    --selected-enabled-background-color: rgb(0, 10, 4);
    --selected-enabled-border-color: rgb(0, 51, 21);
    --selected-hover-background-color: rgb(0, 10, 4);
    --selected-hover-border-color: rgb(0, 61, 26);
    --selected-pressed-background-color: rgb(0, 10, 4);
    --selected-pressed-border-color: rgb(0, 41, 17);
    --selected-focused-background-color: rgb(0, 10, 4);
    --selected-focused-border-color: rgba(0, 51, 21, .500);
    --selected-disabled-background-color: rgb(0, 10, 4);
    --selected-disabled-border-color: rgba(0, 0, 0, .00);
    --on-selected-active-color: rgb(0, 51, 21);
    --on-selected-neutral-color: rgb(0, 36, 15);
    --on-selected-disabled-color: rgb(0, 26, 11);
    --thumb-enabled-background-color: rgb(0, 0, 0);
    --thumb-enabled-border-color: rgb(26, 26, 0);
    --thumb-hover-background-color: rgb(0, 0, 0);
    --thumb-hover-border-color: rgb(36, 36, 0);
    --thumb-pressed-background-color: rgb(7, 7, 7);
    --thumb-pressed-border-color: rgb(15, 15, 0);
    --thumb-focused-background-color: rgb(0, 0, 0);
    --thumb-focused-border-color: rgba(0, 71, 30, .500);
    --thumb-disabled-background-color: rgb(0, 10, 4);
    --thumb-disabled-border-color: rgba(0, 0, 0, .00);
    --on-thumb-active-color: rgb(71, 71, 0);
    --on-thumb-neutral-color: rgb(51, 51, 0);
    --on-thumb-disabled-color: rgb(18, 18, 0);
    --amplified-enabled-background-color: rgba(0, 71, 30, .150);
    --amplified-enabled-border-color: rgba(0, 0, 0, .00);
    --amplified-hover-background-color: rgba(0, 71, 30, .200);
    --amplified-hover-border-color: rgba(0, 0, 0, .00);
    --amplified-pressed-background-color: rgba(0, 71, 30, .080);
    --amplified-pressed-border-color: rgba(0, 0, 0, .00);
    --amplified-focused-background-color: rgba(0, 71, 30, .150);
    --amplified-focused-border-color: rgba(0, 71, 30, .500);
    --amplified-disabled-background-color: rgba(0, 71, 30, .100);
    --amplified-disabled-border-color: rgba(0, 0, 0, .00);
    --on-amplified-active-color: rgb(0, 51, 21);
    --on-amplified-neutral-color: rgb(0, 36, 15);
    --on-amplified-disabled-color: rgb(0, 26, 11);
    --device-enabled-background-color: rgb(31, 31, 0);
    --device-enabled-border-color: rgb(0, 0, 0);
    --device-hover-background-color: rgb(36, 36, 0);
    --device-hover-border-color: rgb(0, 0, 0);
    --device-pressed-background-color: rgb(26, 26, 0);
    --device-pressed-border-color: rgb(0, 0, 0);
    --device-focused-background-color: rgb(31, 31, 0);
    --device-focused-border-color: rgba(0, 71, 30, .500);
    --device-disabled-background-color: rgba(0, 0, 0, .00);
    --device-disabled-border-color: rgba(0, 0, 0, .050);
    --on-device-active-color: rgb(71, 71, 0);
    --on-device-neutral-color: rgb(51, 51, 0);
    --on-device-disabled-color: rgb(18, 18, 0);
    --notification-enabled-background-color: rgb(0, 76, 32);
    --notification-enabled-border-color: rgba(0, 0, 0, .500);
    --notification-hover-background-color: rgb(0, 66, 28);
    --notification-hover-border-color: rgba(0, 0, 0, .100);
    --notification-pressed-background-color: rgb(0, 56, 23);
    --notification-pressed-border-color: rgba(0, 0, 0, .200);
    --notification-focused-background-color: rgb(0, 77, 32);
    --notification-focused-border-color: rgba(0, 51, 21, .500);
    --notification-disabled-background-color: rgb(0, 76, 32);
    --notification-disabled-border-color: rgba(0, 0, 0, .00);
    --on-notification-active-color: rgb(0, 0, 0);
    --on-notification-neutral-color: rgba(0, 0, 0, .600);
    --on-notification-disabled-color: rgba(0, 0, 0, .200);
    --running-enabled-background-color: rgb(23, 56, 0);
    --running-enabled-border-color: rgba(0, 0, 0, .100);
    --running-hover-background-color: rgb(19, 46, 0);
    --running-hover-border-color: rgba(0, 0, 0, .100);
    --running-pressed-background-color: rgb(15, 36, 0);
    --running-pressed-border-color: rgba(0, 0, 0, .200);
    --running-focused-background-color: rgb(23, 56, 0);
    --running-focused-border-color: rgba(0, 51, 21, .500);
    --running-disabled-background-color: rgb(23, 56, 0);
    --running-disabled-border-color: rgba(0, 0, 0, .00);
    --on-running-active-color: rgb(0, 0, 0);
    --on-running-neutral-color: rgba(0, 0, 0, .600);
    --on-running-disabled-color: rgba(0, 0, 0, .200);
    --caution-enabled-background-color: rgb(92, 69, 0);
    --caution-enabled-border-color: rgb(0, 0, 0);
    --caution-hover-background-color: rgb(82, 61, 0);
    --caution-hover-border-color: rgb(0, 0, 0);
    --caution-pressed-background-color: rgb(71, 54, 0);
    --caution-pressed-border-color: rgb(0, 0, 0);
    --caution-focused-background-color: rgb(92, 69, 0);
    --caution-focused-border-color: rgba(0, 51, 21, .500);
    --caution-disabled-background-color: rgb(92, 69, 0);
    --caution-disabled-border-color: rgba(0, 0, 0, .00);
    --on-caution-active-color: rgba(0, 0, 0, .900);
    --on-caution-neutral-color: rgba(0, 0, 0, .600);
    --on-caution-disabled-color: rgba(0, 0, 0, .300);
    --warning-enabled-background-color: rgb(92, 44, 0);
    --warning-enabled-border-color: rgba(0, 0, 0, .100);
    --warning-hover-background-color: rgb(82, 39, 0);
    --warning-hover-border-color: rgba(0, 0, 0, .100);
    --warning-pressed-background-color: rgb(71, 34, 0);
    --warning-pressed-border-color: rgba(0, 0, 0, .200);
    --warning-focused-background-color: rgb(92, 44, 0);
    --warning-focused-border-color: rgba(0, 51, 21, .500);
    --warning-disabled-background-color: rgb(92, 44, 0);
    --warning-disabled-border-color: rgba(0, 0, 0, .00);
    --on-warning-active-color: rgba(0, 0, 0, .900);
    --on-warning-neutral-color: rgba(0, 0, 0, .600);
    --on-warning-disabled-color: rgba(0, 0, 0, .300);
    --alarm-enabled-background-color: rgb(76, 0, 5);
    --alarm-enabled-border-color: rgb(0, 0, 0);
    --alarm-hover-background-color: rgb(66, 0, 4);
    --alarm-hover-border-color: rgb(0, 0, 0);
    --alarm-pressed-background-color: rgb(56, 0, 4);
    --alarm-pressed-border-color: rgb(0, 0, 0);
    --alarm-focused-background-color: rgb(76, 0, 5);
    --alarm-focused-border-color: rgba(0, 51, 21, .500);
    --alarm-disabled-background-color: rgb(76, 0, 5);
    --alarm-disabled-border-color: rgba(0, 0, 0, .00);
    --on-alarm-active-color: rgb(0, 0, 0);
    --on-alarm-neutral-color: rgba(0, 0, 0, .650);
    --on-alarm-disabled-color: rgba(0, 0, 0, .300);
    --critical-alarm-enabled-background-color: rgb(76, 0, 76);
    --critical-alarm-enabled-border-color: rgba(0, 0, 0, .100);
    --critical-alarm-hover-background-color: rgb(66, 0, 66);
    --critical-alarm-hover-border-color: rgba(0, 0, 0, .100);
    --critical-alarm-pressed-background-color: rgb(61, 0, 61);
    --critical-alarm-pressed-border-color: rgba(0, 0, 0, .200);
    --critical-alarm-focused-background-color: rgb(76, 0, 76);
    --critical-alarm-focused-border-color: rgba(0, 51, 21, .500);
    --critical-alarm-disabled-background-color: rgb(76, 0, 76);
    --critical-alarm-disabled-border-color: rgba(0, 0, 0, .00);
    --on-critical-alarm-active-color: rgb(0, 0, 0);
    --on-critical-alarm-neutral-color: rgba(0, 0, 0, .700);
    --on-critical-alarm-disabled-color: rgba(0, 0, 0, .300);
    --font-ui-title-size: 24px;
    --font-ui-title-weight: bold;
    --font-ui-title-line-height: 32px;
    --font-ui-subtitle-size: 24px;
    --font-ui-subtitle-weight: regular;
    --font-ui-subtitle-line-height: 32px;
    --font-ui-overline-size: 12px;
    --font-ui-overline-weight: semibold;
    --font-ui-overline-line-height: 24px;
    --font-ui-button-size: 16px;
    --font-ui-button-weight: semibold;
    --font-ui-button-line-height: 24px;
    --font-ui-body-active-size: 16px;
    --font-ui-body-active-weight: bold;
    --font-ui-body-active-line-height: 24px;
    --font-ui-body-size: 16px;
    --font-ui-body-weight: regular;
    --font-ui-body-line-height: 24px;
    --font-ui-label-active-size: 12px;
    --font-ui-label-active-weight: bold;
    --font-ui-label-active-line-height: 16px;
    --font-ui-label-size: 12px;
    --font-ui-label-weight: regular;
    --font-ui-label-line-height: 16px;
    --font-instrument-label-s-size: 16px;
    --font-instrument-label-s-weight: regular;
    --font-instrument-label-s-line-height: 24px;
    --font-instrument-label-m-size: 32px;
    --font-instrument-label-m-weight: regular;
    --font-instrument-label-m-line-height: 40px;
    --font-instrument-label-l-size: 64px;
    --font-instrument-label-l-weight: regular;
    --font-instrument-label-l-line-height: 80px;
    --font-instrument-value-s-size: 16px;
    --font-instrument-value-s-weight: semibold;
    --font-instrument-value-s-line-height: 24px;
    --font-instrument-value-s-enhanced-size: 24px;
    --font-instrument-value-s-enhanced-weight: semibold;
    --font-instrument-value-s-enhanced-line-height: 32px;
    --font-instrument-value-m-size: 32px;
    --font-instrument-value-m-weight: semibold;
    --font-instrument-value-m-line-height: 40px;
    --font-instrument-value-m-enhanced-size: 48px;
    --font-instrument-value-m-enhanced-weight: semibold;
    --font-instrument-value-m-enhanced-line-height: 64px;
    --font-instrument-value-l-size: 64px;
    --font-instrument-value-l-weight: semibold;
    --font-instrument-value-l-line-height: 80px;
    --font-instrument-value-l-enhanced-size: 96px;
    --font-instrument-value-l-enhanced-weight: semibold;
    --font-instrument-value-l-enhanced-line-height: 128px;
    --font-instrument-unit-s-size: 16px;
    --font-instrument-unit-s-weight: regular;
    --font-instrument-unit-s-line-height: 24px;
    --font-instrument-unit-m-size: 32px;
    --font-instrument-unit-m-weight: regular;
    --font-instrument-unit-m-line-height: 40px;
    --font-instrument-unit-l-size: 64px;
    --font-instrument-unit-l-weight: regular;
    --font-instrument-unit-l-line-height: 80px;
    --font-instrument-status-s-size: 16px;
    --font-instrument-status-s-weight: regular;
    --font-instrument-status-s-line-height: 24px;
    --font-instrument-status-m-size: 32px;
    --font-instrument-status-m-weight: regular;
    --font-instrument-status-m-line-height: 40px;
    --font-instrument-status-l-size: 64px;
    --font-instrument-status-l-weight: regular;
    --font-instrument-status-l-line-height: 80px;
    --shadow-flat: 0px 0px 0px 1px rgb(19, 22, 4);
    --shadow-raised: 0px 0px 0px 1px rgb(19, 22, 4);
    --shadow-floating: 0px 0px 0px 1px rgb(27, 31, 10);
    --shadow-overlay: 0px 0px 0px 1px rgb(30, 35, 6);
}