@import "../reset";
@import "../variables";
@import "../mixins/styles";
@import "../mixins/utilities";
@import "./icon";

$app-btn-width-small: 4 * $base-size !default;
$app-btn-diameter-small: 4 * $base-size !default;
$app-btn-icon-size-small: 2.5 * $base-size !default;
$app-btn-diameter-large: 9 * $base-size !default;
$app-btn-label-height: 2 * $base-size !default;
$app-btn-label-margin: .5 * $base-size !default;
$app-btn-icon-size-large: 5 * $base-size !default;

.ob-app-btn {
  position: relative;
  text-align: center;

  &.ob-large {
    width: $app-btn-diameter-large;

    .ob-btn-icon {
      width: $app-btn-diameter-large;
      height: $app-btn-diameter-large;
      font-size: $app-btn-icon-size-large;
    }

    .ob-label {
      width: $app-btn-diameter-large;
    }
  }

  &.ob-small {
    width: $app-btn-width-small;

    .ob-btn-icon {
      width: $app-btn-diameter-small;
      height: $app-btn-diameter-small;
      font-size: $app-btn-icon-size-small;
    }

    .ob-label {
      width: $app-btn-width-small;
    }
  }

  .ob-btn-icon {
    @include style-raised($app-btn-diameter-small);
    @include color-raised-active;
    @include pointer-on-hover-not-disabled();
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
  }

  .ob-label {
    @include font-ui-label;
    @include color-element-active;
    height: $app-btn-label-height;
    margin-top: $app-btn-label-margin;
    text-align: center;
  }
}
