@import "../reset";
@import "../variables";
@import "./icon";
@import "../mixins/styles";
@import "../mixins/utilities";


$nav-list-item-height: 6 * $base-size;
$nav-list-item-icon-width: 6 * $base-size;
$badge-width: 2.5 * $base-size;
$badge-width-icon: 2.5 * $base-size;
$badge-height: 1.5 * $base-size;
$badge-padding: 1 * $base-size;
$badge-padding-icon: .5 * $base-size;
$badge-border-radius: $border-radius-small;
$badge-icon-height: 1.25 * $base-size;

.ob-nav-list-item {
  @include style-flat(0);
  @include pointer-on-hover-not-disabled;
  display: flex;
  position: relative;
  width: 100%;
  height: $nav-list-item-height;
  padding-left: 1.5 * $base-size - $border-width;

  &.ob-level-2 {
    padding-left: 4 * $base-size - $border-width;
  }

  &.ob-level-3 {
    padding-left: 8 * $base-size - $border-width;
  }

  &.ob-nav-list-item--icon {
    width: $nav-list-item-icon-width;
    padding-left: 0;

    .ob-nav-list-item__icon {
      margin: auto;
    }

    .ob-badge {

      right: $badge-padding-icon - $border-width;
      padding: 0 .25 * $base-size;
      width: unset;

      &.ob-badge--alarm {
        top: $badge-padding-icon - $border-width;

        .ob-text {
          position: relative;
        }
      }

      &.ob-badge--in-command {
        bottom: $badge-padding-icon - $border-width;
      }
    }

  }

  .ob-icon {
    @include color-flat-neutral;
    margin-top: auto;
    margin-bottom: auto;

    &:not(:first-child) {
      margin-left: 1 * $base-size;
    }
  }

  .ob-label {
    @include color-flat-active;
    @include font-ui-body;
    position: relative;
    margin-top: auto;
    margin-bottom: auto;
    flex-grow: 1;

    &:not(:first-child) {
      margin-left: 1 * $base-size;
    }

    &:after {
      position: absolute;
      left: 0;
      bottom: calc(var(--font-ui-body-line-height) / 2 - #{$nav-list-item-height / 2});
      content: "";
      height: $border-width;
      width: 100%;
      border-radius: $border-width;
      background-color: $border-divider-color;
    }
  }

  &.ob-active .ob-label {
    @include font-ui-body-active;
  }

  .ob-badge {
    position: absolute;
    width: $badge-width;
    height: $badge-height;
    border-radius: $badge-border-radius;
    right: $badge-padding - $border-width;

    &.ob-badge--alarm {
      @include font-ui-label-active;
      @include color-alarm-active;
      top: $badge-padding - $border-width;
      background-color: $alert-alarm-color;

      .ob-text {
        top: calc(#{$badge-height / 2} - var(--font-ui-label-active-line-height) / 2);
        position: absolute;
        text-align: center;
        width: $badge-width;
      }
    }

    &.ob-badge--in-command {
      @include color-alarm-active;
      bottom: $badge-padding - $border-width;
      background-color: $instrument-dynamic-color;
      text-align: center;
      font-size: $badge-icon-height;
      line-height: $badge-icon-height;

      .ob-icon {
        @include color-alarm-active;
        line-height: $badge-icon-height;
        font-size: $badge-icon-height;
        color: $element-active-inverted-color;
        margin: auto;
        position: relative;
        top: .125 * $base-size;
      }
    }
  }

  .ob-nav-list-item__flyout {
    position: absolute;
    right: 0;
  }

  &.ob-nav-list-item--icon .ob-nav-list-item__flyout {
    right: calc((1 * $base-size - $icon-normal-height) / 2);
    top: calc(($nav-list-item-height - $icon-normal-height) / 2);
  }
}
