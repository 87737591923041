:root[theme=dusk] {
  --element-active-color: rgb(242, 242, 242);
  --element-neutral-color: rgba(255, 255, 255, .550);
  --element-inactive-color: rgba(255, 255, 255, .350);
  --element-disabled-color: rgba(255, 255, 255, .150);
  --element-active-inverted-color: rgb(0, 0, 0);
  --element-neutral-inverted-color: rgba(0, 0, 0, .600);
  --element-inactive-inverted-color: rgba(0, 0, 0, .400);
  --element-disabled-inverted-color: rgba(0, 0, 0, .200);
  --container-backdrop-color: rgb(15, 15, 15);
  --container-section-color: rgb(26, 26, 26);
  --container-background-color: rgb(31, 31, 31);
  --container-global-color: rgb(38, 38, 38);
  --container-overlay-color: rgba(0, 0, 0, .300);
  --border-divider-color: rgba(255, 255, 255, .080);
  --border-outline-color: rgba(0, 0, 0, .900);
  --border-edge-color: rgba(255, 255, 255, .050);
  --border-silhouette-color: rgb(0, 0, 0);
  --border-icon-outline-color: rgba(0, 0, 0, .500);
  --border-solid-color: rgb(51, 51, 51);
  --instrument-enhanced-primary-color: rgb(129, 223, 254);
  --instrument-enhanced-secondary-color: rgb(86, 150, 199);
  --instrument-enhanced-tertiary-color: rgba(92, 139, 193, .200);
  --instrument-regular-primary-color: rgb(230, 230, 230);
  --instrument-regular-secondary-color: rgb(153, 153, 153);
  --instrument-regular-tertiary-color: rgba(171, 171, 171, .150);
  --instrument-frame-primary-color: rgb(51, 51, 51);
  --instrument-frame-secondary-color: rgb(23, 23, 23);
  --instrument-frame-tertiary-color: rgb(5, 5, 5);
  --instrument-tick-mark-primary-color: rgb(230, 230, 230);
  --instrument-tick-mark-secondary-color: rgb(156, 156, 156);
  --instrument-tick-mark-tertiary-color: rgb(115, 115, 115);
  --instrument-starboard-color: rgb(41, 133, 111);
  --instrument-port-color: rgb(186, 86, 122);
  --alert-running-color: rgb(19, 138, 0);
  --alert-caution-color: rgb(237, 206, 6);
  --alert-warning-color: rgb(246, 148, 0);
  --alert-alarm-color: rgb(229, 38, 38);
  --alert-critical-alarm-color: rgb(229, 0, 229);
  --alert-limit-primary-color: rgba(0, 0, 0, .100);
  --alert-limit-secondary-color: rgba(0, 0, 0, .100);
  --automation-device-primary-color: rgb(230, 230, 230);
  --automation-device-secondary-color: rgb(115, 115, 115);
  --automation-device-tertiary-color: rgb(15, 15, 15);
  --automation-device-primary-inverted-color: rgb(0, 0, 0);
  --automation-device-secondary-inverted-color: rgb(51, 51, 51);
  --automation-device-tertiary-inverted-color: rgb(154, 154, 154);
  --automation-pipe-primary-color: rgb(156, 156, 156);
  --automation-pipe-secondary-color: rgb(20, 20, 20);
  --automation-pipe-tertiary-color: rgb(0, 0, 0);
  --automation-pipe-primary-inverted-color: rgb(15, 15, 15);
  --automation-pipe-secondary-inverted-color: rgba(255, 255, 255, .200);
  --automation-pipe-tertiary-inverted-color: rgba(255, 255, 255, .350);
  --automation-pipe-overlay-color: rgba(0, 0, 0, .200);
  --automation-pipe-disabled-color: rgba(0, 0, 0, .100);
  --automation-acid-alkalis: rgb(133, 113, 140);
  --automation-air-ventilation: rgb(133, 133, 133);
  --automation-fire-fighting: rgb(156, 106, 119);
  --automation-flammable-gas: rgb(119, 124, 72);
  --automation-fresh-water: rgb(89, 129, 139);
  --automation-fuel: rgb(153, 114, 74);
  --automation-masses: rgb(136, 118, 102);
  --automation-non-flammable-gas: rgb(112, 122, 97);
  --automation-oil: rgb(101, 94, 37);
  --automation-sea-water: rgb(91, 128, 121);
  --automation-steam: rgb(94, 97, 103);
  --automation-waste-media: rgb(90, 90, 90);
  --normal-enabled-background-color: rgb(51, 51, 51);
  --normal-enabled-border-color: rgb(0, 0, 0);
  --normal-hover-background-color: rgb(64, 64, 64);
  --normal-hover-border-color: rgb(0, 0, 0);
  --normal-pressed-background-color: rgb(38, 38, 38);
  --normal-pressed-border-color: rgb(0, 0, 0);
  --normal-focused-background-color: rgb(51, 51, 51);
  --normal-focused-border-color: rgba(87, 121, 162, .500);
  --normal-disabled-background-color: rgba(255, 255, 255, .00);
  --normal-disabled-border-color: rgba(0, 0, 0, .100);
  --normal-activated-background-color: rgb(38, 38, 38);
  --normal-activated-border-color: rgb(0, 0, 0);
  --on-normal-active-color: rgb(242, 242, 242);
  --on-normal-neutral-color: rgba(255, 255, 255, .550);
  --on-normal-disabled-color: rgba(255, 255, 255, .200);
  --raised-enabled-background-color: rgb(125, 125, 125);
  --raised-enabled-border-color: rgb(5, 5, 5);
  --raised-hover-background-color: rgb(140, 140, 140);
  --raised-hover-border-color: rgb(0, 0, 0);
  --raised-pressed-background-color: rgb(112, 112, 112);
  --raised-pressed-border-color: rgb(0, 0, 0);
  --raised-focused-background-color: rgb(125, 125, 125);
  --raised-focused-border-color: rgba(87, 121, 162, .500);
  --raised-disabled-background-color: rgba(0, 0, 0, .00);
  --raised-disabled-border-color: rgba(0, 0, 0, .100);
  --on-raised-active-color: rgb(0, 0, 0);
  --on-raised-neutral-color: rgba(0, 0, 0, .700);
  --on-raised-disabled-color: rgba(255, 255, 255, .200);
  --indent-enabled-background-color: rgba(0, 0, 0, .300);
  --indent-enabled-border-color: rgba(0, 0, 0, .400);
  --indent-hover-background-color: rgba(255, 255, 255, .060);
  --indent-hover-border-color: rgb(15, 15, 15);
  --indent-pressed-background-color: rgba(0, 0, 0, .400);
  --indent-pressed-border-color: rgb(15, 15, 15);
  --indent-focused-background-color: rgba(0, 0, 0, .200);
  --indent-focused-border-color: rgba(87, 121, 162, .500);
  --indent-disabled-background-color: rgba(0, 0, 0, .00);
  --indent-disabled-border-color: rgba(0, 0, 0, .100);
  --on-indent-active-color: rgb(242, 242, 242);
  --on-indent-neutral-color: rgba(255, 255, 255, .550);
  --on-indent-disabled-color: rgba(255, 255, 255, .200);
  --flat-enabled-background-color: rgba(255, 255, 255, .00);
  --flat-enabled-border-color: rgba(0, 0, 0, .00);
  --flat-hover-background-color: rgba(255, 255, 255, .060);
  --flat-hover-border-color: rgba(0, 0, 0, .00);
  --flat-pressed-background-color: rgba(255, 255, 255, .120);
  --flat-pressed-border-color: rgba(0, 0, 0, .00);
  --flat-focused-background-color: rgba(255, 255, 255, .00);
  --flat-focused-border-color: rgba(87, 121, 162, .500);
  --flat-disabled-background-color: rgba(0, 0, 0, .00);
  --flat-disabled-border-color: rgba(0, 0, 0, .00);
  --flat-activated-background-color: rgba(0, 0, 0, .050);
  --flat-activated-border-color: rgba(0, 0, 0, .00);
  --flat-checked-background-color: rgba(48, 87, 146, .100);
  --flat-checked-border-color: rgba(15, 74, 163, .00);
  --on-flat-active-color: rgb(242, 242, 242);
  --on-flat-neutral-color: rgba(255, 255, 255, .550);
  --on-flat-disabled-color: rgba(255, 255, 255, .200);
  --selected-enabled-background-color: rgb(74, 123, 165);
  --selected-enabled-border-color: rgb(0, 0, 0);
  --selected-hover-background-color: rgb(97, 144, 184);
  --selected-hover-border-color: rgb(0, 0, 0);
  --selected-pressed-background-color: rgb(62, 102, 137);
  --selected-pressed-border-color: rgb(0, 0, 0);
  --selected-focused-background-color: rgb(74, 123, 165);
  --selected-focused-border-color: rgba(87, 121, 162, .500);
  --selected-disabled-background-color: rgba(0, 0, 0, .200);
  --selected-disabled-border-color: rgba(0, 0, 0, .00);
  --on-selected-active-color: rgb(255, 255, 255);
  --on-selected-neutral-color: rgba(255, 255, 255, .550);
  --on-selected-disabled-color: rgba(0, 0, 0, .300);
  --thumb-enabled-background-color: rgb(51, 51, 51);
  --thumb-enabled-border-color: rgb(0, 0, 0);
  --thumb-hover-background-color: rgb(64, 64, 64);
  --thumb-hover-border-color: rgb(0, 0, 0);
  --thumb-pressed-background-color: rgb(92, 92, 92);
  --thumb-pressed-border-color: rgb(0, 0, 0);
  --thumb-focused-background-color: rgb(61, 61, 61);
  --thumb-focused-border-color: rgba(87, 121, 162, .500);
  --thumb-disabled-background-color: rgb(50, 50, 50);
  --thumb-disabled-border-color: rgba(0, 0, 0, .00);
  --on-thumb-active-color: rgb(242, 242, 242);
  --on-thumb-neutral-color: rgba(255, 255, 255, .550);
  --on-thumb-disabled-color: rgba(255, 255, 255, .200);
  --amplified-enabled-background-color: rgba(56, 122, 168, .100);
  --amplified-enabled-border-color: rgba(0, 0, 0, .00);
  --amplified-hover-background-color: rgba(56, 122, 168, .160);
  --amplified-hover-border-color: rgba(0, 0, 0, .00);
  --amplified-pressed-background-color: rgba(56, 122, 168, .080);
  --amplified-pressed-border-color: rgba(0, 0, 0, .00);
  --amplified-focused-background-color: rgba(56, 122, 168, .100);
  --amplified-focused-border-color: rgba(56, 122, 168, .500);
  --amplified-disabled-background-color: rgba(0, 0, 0, .100);
  --amplified-disabled-border-color: rgba(0, 0, 0, .00);
  --on-amplified-active-color: rgb(242, 242, 242);
  --on-amplified-neutral-color: rgba(255, 255, 255, .550);
  --on-amplified-disabled-color: rgba(255, 255, 255, .200);
  --device-enabled-background-color: rgb(31, 31, 31);
  --device-enabled-border-color: rgb(26, 26, 26);
  --device-hover-background-color: rgb(46, 46, 46);
  --device-hover-border-color: rgb(26, 26, 26);
  --device-pressed-background-color: rgb(61, 61, 61);
  --device-pressed-border-color: rgb(26, 26, 26);
  --device-focused-background-color: rgb(31, 31, 31);
  --device-focused-border-color: rgba(87, 121, 162, .500);
  --device-disabled-background-color: rgba(0, 0, 0, .00);
  --device-disabled-border-color: rgba(0, 0, 0, .050);
  --on-device-active-color: rgb(255, 255, 255);
  --on-device-neutral-color: rgb(123, 123, 123);
  --on-device-disabled-color: rgb(84, 84, 84);
  --running-enabled-background-color: rgb(19, 138, 0);
  --running-enabled-border-color: rgba(0, 0, 0, .100);
  --running-hover-background-color: rgb(23, 168, 0);
  --running-hover-border-color: rgba(0, 0, 0, .100);
  --running-pressed-background-color: rgb(15, 107, 0);
  --running-pressed-border-color: rgba(0, 0, 0, .200);
  --running-focused-background-color: rgb(19, 138, 0);
  --running-focused-border-color: rgb(247, 247, 247);
  --running-disabled-background-color: rgb(19, 138, 0);
  --running-disabled-border-color: rgba(0, 0, 0, .00);
  --on-running-active-color: rgb(255, 255, 255);
  --on-running-neutral-color: rgba(255, 255, 255, .600);
  --on-running-disabled-color: rgba(255, 255, 255, .200);
  --caution-enabled-background-color: rgb(237, 206, 6);
  --caution-enabled-border-color: rgb(20, 20, 20);
  --caution-hover-background-color: rgb(250, 222, 46);
  --caution-hover-border-color: rgb(20, 20, 20);
  --caution-pressed-background-color: rgb(199, 173, 5);
  --caution-pressed-border-color: rgb(20, 20, 20);
  --caution-focused-background-color: rgb(237, 206, 6);
  --caution-focused-border-color: rgb(247, 247, 247);
  --caution-disabled-background-color: rgb(237, 206, 6);
  --caution-disabled-border-color: rgba(0, 0, 0, .00);
  --on-caution-active-color: rgba(0, 0, 0, .900);
  --on-caution-neutral-color: rgba(0, 0, 0, .600);
  --on-caution-disabled-color: rgba(0, 0, 0, .300);
  --warning-enabled-background-color: rgb(246, 148, 0);
  --warning-enabled-border-color: rgba(0, 0, 0, .100);
  --warning-hover-background-color: rgb(255, 166, 31);
  --warning-hover-border-color: rgba(0, 0, 0, .100);
  --warning-pressed-background-color: rgb(204, 123, 0);
  --warning-pressed-border-color: rgba(0, 0, 0, .200);
  --warning-focused-background-color: rgb(246, 148, 0);
  --warning-focused-border-color: rgb(247, 247, 247);
  --warning-disabled-background-color: rgb(246, 148, 0);
  --warning-disabled-border-color: rgba(0, 0, 0, .00);
  --on-warning-active-color: rgba(0, 0, 0, .900);
  --on-warning-neutral-color: rgba(0, 0, 0, .600);
  --on-warning-disabled-color: rgba(0, 0, 0, .300);
  --alarm-enabled-background-color: rgb(229, 38, 38);
  --alarm-enabled-border-color: rgb(20, 20, 20);
  --alarm-hover-background-color: rgb(232, 64, 64);
  --alarm-hover-border-color: rgb(20, 20, 20);
  --alarm-pressed-background-color: rgb(200, 24, 24);
  --alarm-pressed-border-color: rgb(20, 20, 20);
  --alarm-focused-background-color: rgb(229, 38, 38);
  --alarm-focused-border-color: rgb(247, 247, 247);
  --alarm-disabled-background-color: rgb(229, 38, 38);
  --alarm-disabled-border-color: rgba(0, 0, 0, .00);
  --on-alarm-active-color: rgb(255, 255, 255);
  --on-alarm-neutral-color: rgba(255, 255, 255, .650);
  --on-alarm-disabled-color: rgba(0, 0, 0, .300);
  --critical-alarm-enabled-background-color: rgb(201, 0, 202);
  --critical-alarm-enabled-border-color: rgba(0, 0, 0, .100);
  --critical-alarm-hover-background-color: rgb(152, 0, 153);
  --critical-alarm-hover-border-color: rgba(0, 0, 0, .100);
  --critical-alarm-pressed-background-color: rgb(102, 0, 102);
  --critical-alarm-pressed-border-color: rgba(0, 0, 0, .200);
  --critical-alarm-focused-background-color: rgb(201, 0, 202);
  --critical-alarm-focused-border-color: rgb(247, 247, 247);
  --critical-alarm-disabled-background-color: rgb(201, 0, 202);
  --critical-alarm-disabled-border-color: rgba(0, 0, 0, .00);
  --on-critical-alarm-active-color: rgb(255, 255, 255);
  --on-critical-alarm-neutral-color: rgba(255, 255, 255, .700);
  --on-critical-alarm-disabled-color: rgba(255, 255, 255, .300);
  --notification-enabled-background-color: rgb(129, 212, 254);
  --notification-enabled-border-color: rgba(0, 0, 0, .500);
  --notification-hover-background-color: rgb(90, 191, 242);
  --notification-hover-border-color: rgba(0, 0, 0, .100);
  --notification-pressed-background-color: rgb(60, 167, 221);
  --notification-pressed-border-color: rgba(0, 0, 0, .200);
  --notification-focused-background-color: rgb(129, 212, 254);
  --notification-focused-border-color: rgb(247, 247, 247);
  --notification-disabled-background-color: rgb(129, 212, 254);
  --notification-disabled-border-color: rgba(0, 0, 0, .00);
  --on-notification-active-color: rgb(0, 0, 0);
  --on-notification-neutral-color: rgba(0, 0, 0, .600);
  --on-notification-disabled-color: rgba(0, 0, 0, .200);
  --font-ui-title-size: 24px;
  --font-ui-title-weight: bold;
  --font-ui-title-line-height: 32px;
  --font-ui-subtitle-size: 24px;
  --font-ui-subtitle-weight: regular;
  --font-ui-subtitle-line-height: 32px;
  --font-ui-overline-size: 12px;
  --font-ui-overline-weight: semibold;
  --font-ui-overline-line-height: 24px;
  --font-ui-button-size: 16px;
  --font-ui-button-weight: semibold;
  --font-ui-button-line-height: 24px;
  --font-ui-body-active-size: 16px;
  --font-ui-body-active-weight: bold;
  --font-ui-body-active-line-height: 24px;
  --font-ui-body-size: 16px;
  --font-ui-body-weight: regular;
  --font-ui-body-line-height: 24px;
  --font-ui-label-active-size: 12px;
  --font-ui-label-active-weight: bold;
  --font-ui-label-active-line-height: 16px;
  --font-ui-label-size: 12px;
  --font-ui-label-weight: regular;
  --font-ui-label-line-height: 16px;
  --font-instrument-label-s-size: 16px;
  --font-instrument-label-s-weight: regular;
  --font-instrument-label-s-line-height: 24px;
  --font-instrument-label-m-size: 32px;
  --font-instrument-label-m-weight: regular;
  --font-instrument-label-m-line-height: 40px;
  --font-instrument-label-l-size: 64px;
  --font-instrument-label-l-weight: regular;
  --font-instrument-label-l-line-height: 80px;
  --font-instrument-value-s-size: 16px;
  --font-instrument-value-s-weight: semibold;
  --font-instrument-value-s-line-height: 24px;
  --font-instrument-value-s-enhanced-size: 24px;
  --font-instrument-value-s-enhanced-weight: semibold;
  --font-instrument-value-s-enhanced-line-height: 32px;
  --font-instrument-value-m-size: 32px;
  --font-instrument-value-m-weight: semibold;
  --font-instrument-value-m-line-height: 40px;
  --font-instrument-value-m-enhanced-size: 48px;
  --font-instrument-value-m-enhanced-weight: semibold;
  --font-instrument-value-m-enhanced-line-height: 64px;
  --font-instrument-value-l-size: 64px;
  --font-instrument-value-l-weight: semibold;
  --font-instrument-value-l-line-height: 80px;
  --font-instrument-value-l-enhanced-size: 96px;
  --font-instrument-value-l-enhanced-weight: semibold;
  --font-instrument-value-l-enhanced-line-height: 128px;
  --font-instrument-unit-s-size: 16px;
  --font-instrument-unit-s-weight: regular;
  --font-instrument-unit-s-line-height: 24px;
  --font-instrument-unit-m-size: 32px;
  --font-instrument-unit-m-weight: regular;
  --font-instrument-unit-m-line-height: 40px;
  --font-instrument-unit-l-size: 64px;
  --font-instrument-unit-l-weight: regular;
  --font-instrument-unit-l-line-height: 80px;
  --font-instrument-status-s-size: 16px;
  --font-instrument-status-s-weight: regular;
  --font-instrument-status-s-line-height: 24px;
  --font-instrument-status-m-size: 32px;
  --font-instrument-status-m-weight: regular;
  --font-instrument-status-m-line-height: 40px;
  --font-instrument-status-l-size: 64px;
  --font-instrument-status-l-weight: regular;
  --font-instrument-status-l-line-height: 80px;
  --shadow-flat: 0px 1px 1px 0px rgba(0, 0, 0, .600);
  --shadow-raised: 0px 2px 4px 0px rgba(0, 0, 0, .700);
  --shadow-floating: 0px 4px 16px 0px rgba(0, 0, 0, .600);
  --shadow-overlay: 0px 8px 32px 0px rgba(0, 0, 0, .800);
}
