@import "../reset";
@import "../variables";
@import "../mixins/styles";
@import "../mixins/utilities";

.ob-toggle-button-icon {
  .ob-toggle-button-icon__container {
    @include pointer-on-hover-not-disabled;

    background: $indent-enabled-background-color;
    display: flex;
    border-radius: $border-radius-regular;
  }

  .ob-toggle-button-icon__item {
    @include style-flat;
    border-radius: $border-radius-regular;
    min-width: 6 * $base-size;
    height: 4 * $base-size;
    flex-basis: 0;
    flex-grow: 1;

    .ob-icon {
      width: $icon-normal-height;
      height: $icon-normal-height;
      font-size: $icon-normal-height;
      margin: .5 * $base-size - $border-width  auto;


      @include color-normal-neutral();
    }

    .ob-label {
      @include font-ui-label;
      @include color-normal-active;
      text-align: center;
      height: 3 * $base-size;
    }


    &.ob-selected {
      @include style-amplified;
      border-radius: .75 * $base-size;

      & .ob-label{
        @include font-ui-label-active;
      }

      & .ob-icon{
        @include color-normal-active;
      }
    }
  }
}
