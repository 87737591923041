@import "../reset";
@import "../variables";
@import "../mixins/styles";
@import "../mixins/utilities";
@import "./button";
@import "./icon";

$nav-top-bar-height: 6 * $base-size !default;
$nav-top-bar-width: 100% !default;
$nav-top-bar-border-bottom-width: .25 * $base-size !default;
$nav-top-bar-button-margin-x: 1 * $base-size !default;
$nav-top-bar-clock-margin-x: 2 * $base-size !default;

$nav-btn-height: 3 * $base-size !default;
$nav-top-bar-spacing: calc(($nav-top-bar-height - $nav-btn-height) / 2) !default;

.ob-nav-top-bar {
  @include shadow-raised;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: $nav-top-bar-width;
  height: $nav-top-bar-height;

  background-color: $container-global-color;
  @include color-element-active;

  .ob-menu-container {
    display: flex;
    align-items: center;
    height: 100%;


    .ob-nav-item {
      height: 100%;
      padding: 0 calc(($nav-top-bar-height - $nav-btn-height) / 2);
      @include font-ui-body();


      & > * {
        margin: 0 calc(($nav-top-bar-spacing / 2));

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      &.ob-active {
        @include font-ui-body-active;
        @include style-selected();
        @include color-selected-active;
      }
    }

    .ob-button {
      margin: 0 $nav-top-bar-button-margin-x;
    }

    .ob-nav-top-bar__apps > * {
      margin: 0 $nav-top-bar-button-margin-x;
    }

    .ob-clock {
      @include font-ui-body;
      @include color-normal-active;
      margin: 0 $nav-top-bar-clock-margin-x;
    }

    .ob-divider {
      width: $nav-divider-width;
      height: $nav-divider-height;

      background-color: $border-divider-color;
    }

    .ob-title {
      @include font-ui-body;
      margin-left: $nav-title-spacing-x;
    }

    .ob-sub-title {
      @include font-ui-body-active;
      margin-left: $nav-title-spacing-x;
    }

  }
}

$nav-menu-footer-item-padding: 1.5 * $base-size;
$nav-menu-footer-item-icon-padding: 1 * $base-size;

.ob-nav-menu {
  @include font-ui-body();
  @include shadow-floating;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: $nav-top-bar-height - $nav-menu-top-border-thickness;
  bottom: 0;
  left: 0;
  width: $nav-menu-width;

  background-color: $container-global-color;

  .ob-nav-menu-body {
    flex-grow: 1;
  }

  .ob-nav-menu-footer {
    width: 100%;
    border-top: $nav-menu-footer-divider-thickness solid $border-outline-color;

    & .ob-nav-menu-footer-item {
      @include style-flat;
      @include pointer-on-hover-not-disabled;
      position: relative;
      height: 6 * $base-size;
      display: flex;
      flex-direction: row;
      padding: $nav-menu-footer-item-padding;

      & .ob-nav-menu-footer-item__icon {
        @include color-flat-neutral;
      }

      & .ob-nav-menu-footer-item__label {
        @include color-flat-active;
        @include font-ui-body;

        margin-left: $nav-menu-footer-item-icon-padding;
      }

      & .ob-nav-menu-footer-item__divider {
        position: absolute;
        right: -$border-width;
        left: $nav-menu-footer-item-padding + $icon-normal-height + $nav-menu-footer-item-icon-padding;
        bottom: -$border-width;
        height: $border-width;
        background-color: $border-divider-color;
      }
    }

    & .ob-nav-menu-footer-branding {
      height: 12 * $base-size;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      & .ob-nav-menu-footer-branding__icon {
        @include color-flat-neutral;
        height: 6.25 * $base-size;
        font-size: 6.25 * $base-size;
        line-height: 6.25 * $base-size;
      }

      & .ob-nav-menu-footer-branding__name {
        @include color-flat-neutral;
      }
    }
  }
}
